import { RemoteOrchestraSpecInfo } from './OrchestraSpecManager';
import { OrchHubSpecIdentifier } from './specIdentifier';
import { fetchOrchHubSpecList } from './orchHubApi';

export async function getOrchHubSpecInfo(
  orchestraHubUrl: string
): Promise<RemoteOrchestraSpecInfo[]> {
  const specList = await fetchOrchHubSpecList(orchestraHubUrl);

  return specList.map((spec) => ({
    type: 'remote',
    forceReloadCount: 0,
    slug: createFullOrchHubSlug({
      group: spec.group || 'official',
      slug: spec.slug,
      version: spec.version,
    }),
    name: spec.name,
    url: `${orchestraHubUrl}${spec.path}`,
  }));
}

export function createFullOrchHubSlug({
  group,
  slug,
  version,
}: Omit<OrchHubSpecIdentifier, 'type'>): string {
  if (group === 'official') {
    return `-/${slug}/${version}`;
  } else {
    return `r/${group}/${slug}/${version}`;
  }
}
